import React from "react";
import {
  Container,
  Block,
  MediaImage,
} from './media-components.styled';


const MediaGrid = ({ list }) => (
  <Container>
    {list.map((row, key) => (
      <Block href={row.url} target="_blank" key={key}>
        <MediaImage
          image={row.logo?.childImageSharp.gatsbyImageData}
          alt={row.name}
        />
      </Block>
    )
    )}
  </Container>
);


export default MediaGrid;
