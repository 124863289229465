import React from "react";
import {
  Table,
  TableCell,
  MediaImage,
} from './media-components.styled';


const MediaList = ({ list }) => (
  <>
    {list.map((row, key) => (
      <Table angle="6" key={key}>
        <TableCell image>
          <a href={row.url} target="_blank">
            <MediaImage
              image={row.logo?.childImageSharp.gatsbyImageData}
              alt={row.name}
            />
          </a>
        </TableCell>
        <TableCell text>
          {row.quote
            ? `\"${row.quote}\"`
            : ''
          }
        </TableCell>
      </Table>
    ))
    }
  </>
);


export default MediaList;
