import styled, { css } from "styled-components";
import {
  GatsbyImage,
} from "gatsby-plugin-image";
import { ZWrapper } from "../../ui-components";


export const Table = styled(ZWrapper)`
display: table;
width: 100%;
margin-bottom: 1px;
text-align: left;

@media ${({ theme }) => theme.device.phone}{
  display: block;   
}
`;

export const TableCell = styled.div`
display: table-cell;
vertical-align: middle;

${props => props.image && css`
  width: 160px;
  text-align: center;

  @media ${({ theme }) => theme.device.phone}{
    display: block; 
    width: auto;
  }
`}

${props => props.text && css`
  font-size: 18px;
  line-height: 25px;
  font-style: italic;

  padding: 16px 24px 16px 8px;

  color: ${({ theme }) => theme.text.primary};
  font-weight: 300;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 0;
    padding-bottom: 8px;
  }
`}
`;

export const MediaImage = styled(GatsbyImage)`
  @media ${({ theme }) => theme.device.phone}{
    margin: 8px 0;
  }
`;


export const Container = styled(ZWrapper)`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
padding: 24px 0;
width: 100%;
`;


export const Block = styled.a`
display: block;
padding: 0 8px;
/* margin-top: -4px; */
/* margin-bottom: -4px; */

@media ${({ theme }) => theme.device.mobile}{
  margin: 0;
}
`;
