import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import MediaList from "../components/media-components/media-list";
import MediaGrid from "../components/media-components/media-grid";
import PreOrder from "../components/pre-order/pre-order";



const MediaCoveragePage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  const quoteCoverage = data.quoteCoverage.edges.map(element => element.node.childMarkdownRemark.frontmatter) || [];
  const noQuoteCoverage = data.noQuoteCoverage.edges.map(element => element.node.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/media-coverage/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <PageHTML center dangerouslySetInnerHTML={{ __html: html }} />

          <MediaList list={quoteCoverage} />
          <br />
          <MediaGrid list={noQuoteCoverage} />

          <PreOrder />
        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default MediaCoveragePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/media_coverage.md/"}) {
      frontmatter {
        title
      }
      html
    }
    quoteCoverage: allFile(filter: {absolutePath: {regex: "/content/media_coverage/"}, childMarkdownRemark: {frontmatter: {quote: {ne: null}}}}, sort: {fields: [childMarkdownRemark___frontmatter___name], order: [ASC]}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name
              url
              quote
              logo{
                childImageSharp {
                  gatsbyImageData(
                    width: 120
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    noQuoteCoverage: allFile(filter: {absolutePath: {regex: "/content/media_coverage/"}, childMarkdownRemark: {frontmatter: {quote: {eq: null}}}}, sort: {fields: [childMarkdownRemark___frontmatter___name], order: [ASC]}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name
              url
              quote
              logo{
                childImageSharp {
                  gatsbyImageData(
                    width: 120
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
